html,
body {
  font-family: "DM Sans", "Gilroy", -apple-system, "Sofia Pro",
    "Microsoft YaHei", "Helvetica", "Arial", sans-serif;
  color: #333;
  line-height: 1.3;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-size: 14px;
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
section {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.header {
  font-size: 40px;
  letter-spacing: 10px;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/images/banner.png") no-repeat 100% 100%;
  background-size: 100% 100%;
  color: white;
}
body {
  scroll-behavior: smooth;
  background-color: #f2f3f7;
}
.container {
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  .main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .header {
      flex: none;
    }
    .body {
      height: 80vh;
      flex: auto;
      padding: 0 10px;
    }
    .list {
      height: calc(80vh - 110px);
      overflow-y: auto;
    }
    .beian {
      height: 40px;
      text-align: center;
      color: #666;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        margin-left: 5px;
        &.img {
          margin-right: 10px;
        }
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.search {
  padding: 10px 0;
  input[type="text"] {
    border-radius: 20px 0 0 20px;
    background-color: #fff;
    height: 50px;
    line-height: 1;
    padding: 5px 20px;
    border-width: 0;
    font-size: 14px;
  }
  input[type="submit"] {
    background: rgb(20 111 189);
    border-width: 0;
    color: #fff;
    border-radius: 0 20px 20px 0;
    padding: 0 20px;
    background: linear-gradient(45deg, rgba(20 111 189) 0%, #2caeff 100%);
  }
}
.race-item {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  &:hover {
    background: #fefefe;
  }
  .item-img {
    width: 80px;
    height: 64px;
    margin-right: 20px;
    & > div {
      height: 100%;
      width: 100%;
    }
    img {
      border-radius: 10px;
    }
  }
  .item-text {
    h5 {
      font-size: 14px;
      padding-bottom: 10px;
    }
    summary {
      color: #999;
      font-size: 12px;
      img {
        transform: translateY(3px);
      }
      span {
        align-self: baseline;
        padding: 0 5px;
        &.date {
          padding-right: 20px;
        }
      }
    }
  }
  .more {
    width: 10px;
  }
}
.btn {
  display: none;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  line-height: 40px;
  height: 40px;
  &:hover {
    background-color: #fafafa;
  }
}

.more {
  width: 10px;
  height: 10px;
  background-color: transparent; /* 模块背景为透明 */
  border-style: solid;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}
