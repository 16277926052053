.max-line1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max-line2 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.button {
  display: inline-block;
  min-width: 160px;
  height: 44px;
  line-height: 44px;
  border-radius: 38px;
  background-color: #1d83c2;
  cursor: pointer;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 14px;
  letter-spacing: 4px;
  &:hover {
    background-color: #1d83c2;
  }
  &.button-primary {
    background-color: #1d83c2;
    color: #fff;
    &:hover {
      background-color: #1d83c2;
    }
  }
}
.back {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
  }
  &::after {
    height: 2px;
    background-color: #707070;
    width: 40px;
  }
  &::before {
    width: 20px;
    height: 20px;
    border: solid #707070;
    border-width: 0 0 2px 2px;
    transform: translate(-80%, -50%) rotate(45deg);
  }
  &:hover {
    &::after {
      background-color: #1d83c2;
    }
    &::before {
      border-color: #1d83c2;
    }
  }
}
.empty {
  min-height: 100px;
  color: #aaa;
}
.cursor-pointer {
  cursor: pointer;
}
.arrow {
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 50%;
  border: solid 1px transparent;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
  &.big {
    width: 40px;
    height: 40px;
    &::after {
      width: 10px;
      height: 10px;
      border-width: 0 0 2px 2px;
    }
    &::before {
      width: 16px;
      height: 2px;
    }
  }
  &:hover {
    border-color: #1d83c2;
    &::before {
      background-color: #1d83c2;
    }
    &::after {
      border-color: #1d83c2;
    }
  }
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
  }
  &::before {
    width: 12px;
    height: 1px;
    background-color: #707070;
  }
  &::after {
    width: 8px;
    height: 8px;
    border: solid #707070;
    border-width: 0 0 1px 1px;
    transform: translate(-50%, -50%) rotate(-135deg);
  }
  &.arrow-left {
    transform: rotate(-180deg);

    &:hover {
      transform: rotate(-180deg) scale(1.1);
    }
  }
  &.arrow-right {
    &:hover {
      transform: scale(1.1);
    }
  }
  &:disabled {
    &::before {
      background-color: #ccc;
    }
    &::after {
      border-color: #ccc;
    }
    &:hover {
      &::before {
        background-color: #ccc;
      }
      &::after {
        border-color: #ccc;
      }
    }
  }
}
.tabs_container {
  input[type="radio"] {
    display: none;
    @for $i from 0 through 10 {
      &#tab-#{$i} {
        &:checked {
          & ~ .panels #panel-#{$i} {
            display: block;
            transition: all 0.2s;
            &.panel-grid {
              display: grid;
            }
          }
          & ~ .tabs #label-#{$i} {
            color: #1a0b0b;
            font-weight: bold;
          }
        }
      }
    }
  }
  .tabs {
    border-bottom: 1px solid #707070;
    margin-bottom: 30px;
    label {
      cursor: pointer;
      padding: 20px 30px;
      margin: 0px 2px;
      display: inline-block;
      color: #adadad;
      font-size: 18px;
      transition: color 0.2s;
    }
  }

  .panels {
    padding: 30px 0;
    min-height: 200px;
    .panel {
      color: #888;
      line-height: 2;

      display: none;
      grid-template-columns: repeat(auto-fill, 308px);
      row-gap: 20px;
      justify-content: space-between;
      .card {
        height: auto;
        h5 {
          padding-bottom: 10px;
        }
        & > section {
          padding-bottom: 10px;
          padding-right: 10px;
        }
      }
      a {
        color: #2f47ff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.tabs_container.detail_tabs {
  input[type="radio"] {
    @for $i from 0 through 10 {
      &#tab-#{$i} {
        &:checked {
          & ~ .tabs #label-#{$i} {
            color: #1d83c2;
            border-bottom: solid 6px #1d83c2;
          }
        }
      }
    }
  }
  .tabs {
    text-align: center;
    border-bottom: none;
    label {
      cursor: pointer;
      padding: 20px 30px;
      margin: 0 20px;
    }
  }
}
.modal_checkbox {
  display: none;
  & ~ .mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    & + main {
      position: fixed;
      min-width: 550px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 3;
      .box {
        padding: 60px;
        color: #fff;
        line-height: 1.2;
        border-radius: 45px;
        background-color: #1d83c2;
        margin-bottom: 30px;
        .icon {
          width: 57px;
          height: 57px;
          border-radius: 50%;
          background-color: #fff;
          margin-bottom: 38px;
          position: relative;
          &::after,
          &::before {
            content: "";
            display: inline-block;
            height: 4px;
            width: 30px;
            background-color: #1d83c2;
            position: absolute;
          }
          &.icon-succ {
            &::after {
              transform: translate(5px, 0) rotate(-45deg);
            }
            &::before {
              width: 20px;
              transform: translate(-10px, 3px) rotate(45deg);
            }
          }
          &.icon-fail {
            &::after {
              transform: rotate(-45deg);
            }
            &::before {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}
.pages {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  margin-top: 20px;
  a {
    background-color: #888;
    color: #fff;
    border-radius: 11px;
    height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
      background-color: #1d83c2;
    }
    & + a {
      margin-left: 13px;
    }
  }
}
.none {
  display: none !important;
}
