/* flex */
.flex {
  display: flex;
  &.flex-wrap {
    flex-wrap: wrap;
  }
  .flex-none {
    flex: none;
  }
  .flex-auto {
    flex: auto;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.row {
    flex-direction: row;
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.column {
    flex-direction: column;
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.align-stretch {
    align-items: stretch;
  }
  &.justify-stretch {
    justify-content: stretch;
  }
}
